import { render, staticRenderFns } from "./CompanyLeaveAssignmentDetails.vue?vue&type=template&id=d5a96e94&scoped=true&"
import script from "./CompanyLeaveAssignmentDetails.vue?vue&type=script&lang=js&"
export * from "./CompanyLeaveAssignmentDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5a96e94",
  null
  
)

export default component.exports