<template>
  <AppPageCard>
    <template #card-title> {{ $t("LEAVE.ASSIGNMENT_TITLE") }} </template>

    <template #card-toolbar>
      <div class="btn btn-icon btn-light btn-hover-primary mr-3">
        <span class="svg-icon svg-icon-2x svg-icon-primary">
          <inline-svg src="/media/svg/icons/Media/Repeat.svg" />
        </span>
      </div>
    </template>

    <AppBgLightAlert>
      <span class="font-weight-bold font-size-base d-none d-md-inline mr-1">
        {{ `${$t("EMPLOYEE.EMP")}:` }}
      </span>
      <span
        class="
          text-dark-50
          font-weight-bolder font-size-base
          d-none d-md-inline
          mr-3
        "
      >
        {{ employee.name }}
      </span>
    </AppBgLightAlert>
    <CompanyEmployeeLeaveAssignmentPageTable></CompanyEmployeeLeaveAssignmentPageTable>
  </AppPageCard>
</template>

<script>
import CompanyEmployeeLeaveAssignmentPageTable from "@/modules/company/components/leave/assignment/employee/CompanyEmployeeLeaveAssignmentPageTable";
import { mapGetters } from "vuex";
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import AppBgLightAlert from "@/modules/core/components/utility/info/AppBgLightAlert";
export default {
  name: "CompanyEmployeeLeaveAssignmentPage",
  components: {
    AppBgLightAlert,
    AppPageCard,
    CompanyEmployeeLeaveAssignmentPageTable,
  },
  beforeMount() {
    this.$store.dispatch("assignSelectedAssignmentForEmployee", {
      id: this.$route.query.id,
      name: this.$route.query.name,
    });
  },
  computed: {
    ...mapGetters({ employee: "getSelectedAssignForEmployee" }),
  },
};
</script>

<style scoped></style>
