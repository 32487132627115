<template>
  <div>
    <b-modal
      id="company-employee-leave-assignment-modal"
      title="Assign Leave"
      title-class="h4 font-weight-bold"
      lazy
      size="lg"
      hide-footer
    >
      <div class="d-flex flex-column p-5">
        <CompanyLeaveAssignmentDetails
          class="mb-2"
          :leave="leave"
          :is-busy="false"
        >
        </CompanyLeaveAssignmentDetails>
        <CompanyEmployeeLeaveAssignmentForm></CompanyEmployeeLeaveAssignmentForm>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CompanyLeaveAssignmentDetails from "@/modules/company/components/shared/CompanyLeaveAssignmentDetails";
import CompanyEmployeeLeaveAssignmentForm from "@/modules/company/components/leave/assignment/employee/form/CompanyEmployeeLeaveAssignmentForm";
import { mapGetters } from "vuex";
export default {
  name: "CompanyEmployeeLeaveAssignmentModal",
  components: {
    CompanyEmployeeLeaveAssignmentForm,
    CompanyLeaveAssignmentDetails,
  },
  computed: {
    ...mapGetters({ leave: "getEmployeeLeaveAssignmentDetails" }),
  },
};
</script>

<style scoped></style>
